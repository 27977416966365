import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingCustomers: [],
    };
  },
  computed: {
    ...mapFields("customer", {
      customerIds: "allIds",
      customerIdsCached: "cacheAllIds",
    }),
    ...mapGetters("customer", {
      customerItems: 'list',
      findCustomer: "find",
      customerItemsCached: 'listCache',
    }),
  },
  methods: {
    ...mapActions("customer", {
      retrieveCustomer: "load",
      fetchAllCustomers: 'fetchAll',
    }),
    resolveCustomer(itemId) {
      if (!this.customerIdsCached.includes(itemId) && !this.loadingCustomers.includes(itemId)) {
        this.loadingCustomers.push(itemId);
        this.retrieveCustomer(decodeURIComponent(itemId));
      }
      return this.findCustomer(itemId);
    },
    resolveCustomers(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveCustomer(itemId);
      }).filter(e => e);
    },
    getCustomerItem(customerIri, type) {
      if (customerIri !== null) {
        let customer = this.resolveCustomer(customerIri);
        if (customer) {
          return customer[type];
        }
      }
      return '';
    }
  }
};
